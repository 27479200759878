import React, { useState, useEffect } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import "./App.css";
import Sidebar from "./Pages/Sidebar/Sidebar";
import Navigation from "./Pages/Navigation/Navigation";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import ForgotPas from "./Pages/ForgotPas";
import { ToastContainer } from "react-toastify";
import OutputFile from "./Pages/DashboardPg/OutputFile";
import StoredFile from "./Pages/DashboardPg/StoredFile";
import InputFile from "./Pages/DashboardPg/InputFile";
import Dashboard from "./Pages/DashboardPg/Dashboard";

function App() {
	const [loggedIn, setLoggedIn] = useState(
		sessionStorage.getItem("loggedIn") === "true"
	);

	useEffect(() => {
		const storedLoggedIn = sessionStorage.getItem("loggedIn") === "true";

		if (storedLoggedIn) {
			setLoggedIn(true);
		}
	}, []);

	const handleLogout = () => {
		setLoggedIn(false);
		sessionStorage.setItem("loggedIn", "false");
	};

	return (
		<>
			<ToastContainer />
			<Router>
				{loggedIn ? (
					<div className="whole__section">
						<div className="side__navigation" id="sidebar">
							<Sidebar />
						</div>
						<div className="content__area" id="content">
							<div className="header">
								<Navigation loggedIn={loggedIn} onLogout={handleLogout} />
							</div>
							<div className="main-container">
								<Routes>
									<Route
										path="/upload-file"
										element={
											loggedIn ? <InputFile /> : <Navigate to="/login" />
										}
									/>
									{/* <Route
										path="/output-file"
										element={
											loggedIn ? <OutputFile /> : <Navigate to="/login" />
										}
									/>
									<Route
										path="/stored-file"
										element={
											loggedIn ? <StoredFile /> : <Navigate to="/login" />
										}
									/>
									<Route
										path="/dashboard"
										element={
											loggedIn ? <Dashboard /> : <Navigate to="/login" />
										}
									/> */}
									<Route path="/" element={<Navigate to="/upload-file" />} />
								</Routes>
							</div>
						</div>
					</div>
				) : (
					<Routes>
						<Route
							path="/login"
							element={<Login setLoggedIn={setLoggedIn} />}
						/>
						<Route path="/signup" element={<SignUp />} />
						<Route path="/ForgotPas" element={<ForgotPas />} />
						<Route path="/" element={<Navigate to="/login" />} />
					</Routes>
				)}
			</Router>
		</>
	);
}

export default App;
