import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navigation from "../Navigation/Navigation";
import "../../App.css";
import axios from "axios";
import { Table, Button, Space, Popconfirm, message, Input } from "antd";
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import { apiEndPoints } from "../../constants/apiEndPoints";
const { Search } = Input;
function StoredFile() {
	const [invoiceData, setInvoiceData] = useState([]);
	const [searchText, setSearchText] = useState("");

	const fetchInvoiceData = () => {
		const token = localStorage.getItem("token");

		axios
			.get(apiEndPoints.invoice_data, {
				headers: {
					Authorization: `Token ${token}`,
				},
				params: {
					search: searchText,
				},
			})
			.then((response) => {
				setInvoiceData(response.data);
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
	};

	useEffect(() => {
		fetchInvoiceData();
	}, [searchText]);

	const handleDelete = (record) => {
		// Send delete request to API
		const token = localStorage.getItem("token");
		axios
			.delete(`${apiEndPoints.invoice_data}${record.id}/`, {
				headers: {
					Authorization: `Token ${token}`,
				},
			})
			.then((response) => {
				message.success("Record deleted successfully");

				fetchInvoiceData();
			})
			.catch((error) => {
				console.error("Error deleting record:", error);
				message.error("Failed to delete record");
			});
	};

	const handleFileDownload = async (record, fileType) => {
		if (fileType === "excel") {
			try {
				const token = localStorage.getItem("token");
				const response = await axios.get(
					`${apiEndPoints.excel_api_endpoint}${record.id}/`,

					{
						responseType: "blob",
						headers: {
							Authorization: `Token ${token}`,
						},
					}
				);
				var filename = "Invoice_excel_" + record.id + ".xlsx";
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename);
				document.body.appendChild(link);
				link.click();

				window.URL.revokeObjectURL(url);
				document.body.removeChild(link);
			} catch (error) {
				console.error("Error occurred while downloading Excel file:", error);
			}
		} else if (fileType === "pdf") {
			try {
				const token = localStorage.getItem("token");
				const response = await axios.get(
					`${apiEndPoints.pdf_api_endpoint}${record.id}/`,

					{
						responseType: "blob",
						headers: {
							Authorization: `Token ${token}`,
						},
					}
				);
				var PDFfilename = "Invoice_PDF_" + record.id + ".pdf";
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", PDFfilename);
				document.body.appendChild(link);
				link.click();

				window.URL.revokeObjectURL(url);
				document.body.removeChild(link);
			} catch (error) {
				console.error("Error occurred while downloading Excel file:", error);
			}
		}
	};

	const columns = [
		{
			title: "SR No",
			key: "sr_no",
			render: (text, record, index) => index + 1,
		},
		{
			title: "Invoice No",
			dataIndex: "invoice_number",
			key: "invoice_number",
		},
		{
			title: "Organizations Name",
			dataIndex: "organizations",
			key: "organizations",
		},
		{
			title: "Email",
			dataIndex: "patient_email",
			key: "patient_email",
		},
		{
			title: "Website",
			dataIndex: "website",
			key: "website",
			render: (text, record) => (
				<a href={text} target="_blank" rel="noopener noreferrer">
					{text}
				</a>
			),
		},

		{
			title: "Phone",
			dataIndex: "phone",
			key: "phone",
		},
		{
			title: "Created Date",
			dataIndex: "created_date",
			key: "created_date",
			render: (date) => date.substring(0, 10),
		},
		{
			title: "Patient Name",
			dataIndex: "patient_name",
			key: "patient_name",
		},
		{
			title: "Excel file",
			key: "excel_icon",
			render: (text, record) => (
				<div className="d-flex">
					<img
						src="Images/pdf.png"
						className="img-fluid  pdf-img"
						alt="PDF logo"
						onClick={() => handleFileDownload(record, "pdf")}
					/>
					<img
						src="Images/excel.png"
						className="img-fluid  excel-img"
						alt="logo"
						onClick={() => handleFileDownload(record, "excel")}
					/>
				</div>
			),
		},

		{
			title: "Action",
			key: "action",
			render: (data, record) => (
				<span className="Action-button">
					<Popconfirm
						title="Are you sure to delete this record?"
						onConfirm={() => handleDelete(record)}
						okText="Yes"
						cancelText="No">
						<Button
							type="danger"
							icon={<DeleteOutlined style={{ color: "red" }} />}
						/>
					</Popconfirm>
				</span>
			),
		},
	];
	const handleSearch = (value) => {
		setSearchText(value);
	};

	return (
		<>
			<div className="search-bar my-4 mx-2 d-flex">
				<Search
					placeholder="Search by Organizations Name,invoice Number and uploaded Date"
					allowClear
					onSearch={handleSearch}
				/>
				<Button type="primary" className="w-0 mx-2 downloadAll">
					Download All Excel..
				</Button>
			</div>
			<div className="working__area close__sidebar ">
				<Table
					dataSource={invoiceData}
					columns={columns}
					scroll={{ x: 1200 }}
				/>
			</div>
		</>
	);
}

export default StoredFile;
