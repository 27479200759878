import React, { useState } from "react";
import axios from "axios";
import InputField from "../Component/InputField";
import { apiEndPoints } from "../constants/apiEndPoints";
import { message } from "antd";
import "../App.css";
import { NavLink } from "react-router-dom";

const initialUserData = {
	email: "",
	new_password: "",
	confirm_password: "",
};

export default function ForgotPas() {
	const [userData, setUserData] = useState(initialUserData);

	const handleChange = (event) => {
		const { id, value } = event.target;
		setUserData((prevData) => ({
			...prevData,
			[id]: value,
		}));
	};
	const handleSubmit = async (event) => {
		event.preventDefault();
		const res = {
			email: userData.email,
			new_password: userData.new_password,
			confirm_password: userData.confirm_password,
		};
		// console.log(res, "response");

		try {
			const response = await axios.post(apiEndPoints.forgot_password, {
				email: userData.email,
				new_password: userData.new_password,
				confirm_password: userData.confirm_password,
			});

			if (response.data.message) {
				message.info(response.data.message);
			}
		} catch (error) {
			console.error("Error occurred:", error);

			if (
				error.response &&
				error.response.data &&
				error.response.data.message
			) {
				message.error(error.response.data.message);
			}
		}
	};
	return (
		<>
			<section className="login-in-page">
				<div className="login-in-page-bg ">
					<div className="row d-flex">
						<div className="col-md-6 text-center login-img-sec">
							<div className="login-in-detail text-white forgot-pass">
								<div className="top">
									<h1>EZBilling</h1>
								</div>
								<div className="img">
									<img
										src="Images/Tax-amico.png"
										className="img-fluid mb-4"
										alt="logo"
									/>
								</div>
								{/* <div>
									<p>
										Efficiency in Every Scan, Precision in Every Bill: Mediscan
										Manages Your Invoices with Care.
									</p>
								</div> */}
							</div>
						</div>
						<div className="col-md-6 position-relative">
							<div className="login-in-from forgot-pass-form ">
								<h3 className="mb-0 mt-2">Forgot Your Password ?</h3>

								<form className="mt-4" onSubmit={handleSubmit}>
									<InputField
										id="email"
										type="email"
										placeholder="Enter Email ID"
										label="Email ID"
										onChange={handleChange}
										required
									/>
									<InputField
										id="new_password"
										type="password"
										placeholder="Enter New Password"
										label="New password"
										onChange={handleChange}
										required
									/>
									<InputField
										id="confirm_password"
										type="password"
										placeholder="Confirm Password"
										label="Confirm Password"
										onChange={handleChange}
										required
									/>

									<div className="d-flex w-100 justify-content-between  align-items-center mt-3 w-100 py-2">
										<button
											type="submit"
											className="btn btn-primary  float-end">
											Submit
										</button>
										<button
											type="button"
											className="btn btn-primary  float-end">
											<NavLink to="/login" className="navLink">
												Back
											</NavLink>
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
