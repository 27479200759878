import React from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";
import "../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faFileLines,
  faDatabase,
  faCalendarWeek
} from "@fortawesome/free-solid-svg-icons";

function Sidebar() {
  return (
    <div>
      <div className="iq-sidebar">
        <div className="iq-sidebar-logo  my-2 text-center">
          <span>EZBilling</span>
          <div className="iq-menu-bt-sidebar">
            <div className="iq-menu-bt align-self-center">
              <div className="wrapper-menu">
                <div className="main-circle">
                  <i className="ri-more-fill" />
                </div>
                <div className="hover-circle">
                  <i className="ri-more-2-fill" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id=""
          data-scrollbar="true"
          tabIndex="-1"
          style={{ overflow: "hidden", outline: "none" }}
        >
          <div
            className="scroll-content"
            style={{ transform: "translate3d(0px, 0px, 0px)" }}
          >
            <nav className="iq-sidebar-menu">
              <ul className="iq-menu mt-4">
                {/* <li>
                  <NavLink to="/dashboard">
                    <FontAwesomeIcon icon={faHouse} />
                    <span>Dashboard</span>
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to="/upload-file" activeclassname="active">
                    <FontAwesomeIcon icon={faFileLines} />
                    <span>Upload File</span>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/output-file" activeclassname="active">F
                    <FontAwesomeIcon icon={faCalendarWeek} />
                    <span>Output File</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/stored-file" activeclassname="active">
                    <FontAwesomeIcon icon={faDatabase} />
                    <span>Stored File</span>
                  </NavLink>
                </li> */}
              </ul>
            </nav>
            <div className="p-3" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
