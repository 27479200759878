import React, { useState } from "react";
import "../../App.css";
import axios from "axios";
import { Upload, Button, message, Spin, Progress } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { apiEndPoints } from "../../constants/apiEndPoints";

export default function InputFile() {
	const [file, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [progress, setProgress] = useState(0);

	const handleUpload = async () => {
		try {
			setUploading(true);
			const formData = new FormData();
			for (let i = 0; i < file.length; i++) {
				formData.append("file", file[i].originFileObj);
			}
			const token = localStorage.getItem("token");
			const response = await axios.post(apiEndPoints.input_file, formData, {
				headers: {
					Authorization: `Token ${token}`,
					"Content-Type": "multipart/form-data",
				},
				onUploadProgress: (progressEvent) => {
					const totalSize = file.reduce(
						(acc, current) => acc + current.size,
						0
					);
					const uploadedSize = progressEvent.loaded;
					const uploadPercentage = Math.round((uploadedSize / totalSize) * 70);
					setProgress(uploadPercentage);
				},
			});
			handleCSVFileDownload();
			message.success("Files uploaded successfully");
			setFileList([]);
			setUploading(false);
			setProgress(100);
			document.querySelector(".ant-upload-list").innerHTML = "";
		} catch (error) {
			console.error("Error occurred:", error);

			let errorMessage =
				"An error occurred while processing your request. Please try again later.";
			if (
				error.response &&
				error.response.data &&
				error.response.data.message
			) {
				errorMessage = error.response.data.message;
			}
			setUploading(false);
			setProgress(0);
			message.error(errorMessage);
		} finally {
			setFileList([]);
			setUploading(false);
		}
	};
	const handleCSVFileDownload = async () => {
		try {
			const token = localStorage.getItem("token");
			const response = await axios.get(apiEndPoints.excel_api_endpoint, {
				responseType: "blob",
				headers: {
					Authorization: `Token ${token}`,
				},
			});
			setFileList([]);
			var filename = "Invoice_excel.csv";
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", filename);
			document.body.appendChild(link);
			link.click();

			window.URL.revokeObjectURL(url);
			document.body.removeChild(link);
			window.location.reload();
		} catch (error) {
			console.error("Error occurred while downloading Excel file:", error);
		}
	};
	const handleChange = (info) => {
		setProgress(0);

		if (info.file.status !== "removed") {
			if (Array.isArray(info.fileList)) {
				setFileList(info.fileList);
			} else {
				setFileList([info.file]);
			}
		}
	};

	return (
		<div>
			<div className="working__area close__sidebar">
				<Spin tip="File uploading..." spinning={uploading} size="large">
					<div className="white_bg_sec">
						<h3>Billing Invoice Processing</h3>
						<p>
							Extract data accurately from any image or pdf document in any
							layout without any manual setup. Our deep learning data extraction
							technology immensely reduces manual errors and saves countless
							hours every month.
						</p>

						<div className="background-sec ">
							<Upload.Dragger
								multiple={true}
								onChange={handleChange}
								beforeUpload={() => false}>
								<p className="ant-upload-drag-icon">
									<UploadOutlined />
								</p>
								<p className="ant-upload-text">
									Supports PDF and images.
									<br />
									Drag or drop files to upload
								</p>
							</Upload.Dragger>

							<Button
								type="primary"
								className="upload_btn"
								onClick={handleUpload}
								loading={uploading}
								disabled={file.length === 0 || uploading} // Disable button while uploading
								style={{ marginTop: 16 }}>
								{uploading ? "Uploading" : "Upload"}
							</Button>

							{/* Progress Bar */}
							{/* <Progress
							percent={progress}
							status={uploading ? "active" : "normal"} // Show progress bar only when uploading
							style={{ marginTop: 16 }}
						/> */}
						</div>
					</div>
				</Spin>
			</div>
		</div>
	);
}
