const baseApi = "https://invoice-scanner.brainvire.net/";
// const baseApi = "https://mediscan-api.brainvire.net/";

export const apiEndPoints = {
  // post user register
  userRegister: baseApi + "api/register/",

  // post user Login
  userLogin: baseApi + "api/login/",

  // forgot password
  forgot_password: baseApi + "api/change-password/",

  // Upload input file
  input_file: baseApi + "Invoice_Extract/",

  // export API
  excel_api_endpoint: baseApi + "download-csv/",

  // pdf API
  pdf_api_endpoint: baseApi + "api/invoices/download_pdf/",

  // Dashboard chart API
  dashboard_chartAPI: baseApi + "dashboard/",

  // user Profile
  userProfile: baseApi + "api/profile/",

  // invoice table data
  invoice_data: baseApi + "api/invoices/",

  // invoice today table data
  invoiceTodayData: baseApi + "api/invoices/?created_today=true/?"
};
